<template>
    <v-form class="mb-6" @submit.prevent="() => {}" ref="form">
        <v-row class="ma-0">
            <div class="ml-4">
                <v-row class="ma-0" align="center">
                    <CustomActivatorSelect
                        class="master__input ml-0"
                        v-model="currentMasterId"
                        @input="addMaster"
                        :items="availableStaffList"
                        :multiple="false"
                    >
                        <template v-slot:activator="{ selected, _id }">
                            <BaseInputOutlined
                                label="Мастер"
                                hide-details
                                class="select-input"
                                append-icon="mdi-menu-down"
                                :id="_id"
                                :value="selected ? selected.name : ''"
                                readonly
                            >
                            </BaseInputOutlined>
                        </template>
                    </CustomActivatorSelect>
                </v-row>
                <div class="message-parts-editor mt-3">
                    <div class="master" v-for="master in includedStaffList" :key="master.id">
                        <BaseInputOutlined
                            label="Мастер"
                            hide-details
                            class="select-input master__input"
                            :id="master.id"
                            :value="master.name"
                            readonly
                        >
                        </BaseInputOutlined>
                        <v-btn icon @click="deleteMaster(master.id)">
                            <v-icon>mdi-delete-outline</v-icon></v-btn
                        >
                    </div>
                </div>
            </div>
        </v-row>
    </v-form>
</template>
<script>
import { defineComponent, ref, computed, watch, getCurrentInstance, onMounted } from 'vue'
import { getRecodingStaffList } from '@api/chisai/GCB2'
import useRouter from '../../../../../hooks/useRouter'
import * as _ from 'lodash'
import BaseInputOutlined from '../../../../inputs/BaseInputOutlined.vue'
import CustomActivatorSelect from '../../../../inputs/CustomActivatorSelect.vue'
import { onBeforeMount } from 'vue'

export default defineComponent({
    name: 'StaffStep',
    components: { BaseInputOutlined, CustomActivatorSelect },
    props: {
        value: { type: Object, required: true },
    },
    setup(props, { emit }) {
        const root = getCurrentInstance().proxy
        const { route } = useRouter(root)
        const project = computed(() => root.$store.getters.projectById(route.value.params.id))
        const sraffIdsList = ref(props.value.staffIds)
        const triggerValue = ref(props.value)

        const currentMasterId = ref()
        const selectedMaster = computed(() => {
            return staffList.value.find(el => el.id === currentMasterId.value)
        })
        const staffList = ref([])
        const availableStaffList = computed(() => {
            const includedStaffIds = new Set(includedStaffList.value.map(ex => ex.id))

            return staffList.value.map(el => ({
                ...el,
                disabled: includedStaffIds.has(el.id),
            }))
        })
        const includedStaffList = ref([])
        const clear = () => {
            includedStaffList.value = []
        }

        const addMaster = () => {
            includedStaffList.value.push({
                id: selectedMaster.value.id,
                name: selectedMaster.value.name,
            })
            currentMasterId.value = ''
        }
        const deleteMaster = id =>
            (includedStaffList.value = includedStaffList.value.filter(el => el.id !== id))
        const updateTrigger = () => {
            triggerValue.value.staffIds = includedStaffList.value.map(el => el.id)
            emit('update-trigger', triggerValue.value)
        }
        watch(
            () => includedStaffList.value,
            v => {
                updateTrigger()
            },
            { deep: true, immediate: true }
        )
        onMounted(async () => {
            const res = await getRecodingStaffList({ projectId: project.value.id }).then(d => d.data)
            staffList.value = res
            includedStaffList.value = staffList.value?.filter(el => sraffIdsList.value?.includes(el.id)) || []
        })

        return {
            clear,
            staffList,
            includedStaffList,
            addMaster,
            deleteMaster,
            currentMasterId,
            selectedMaster,
            availableStaffList,
            sraffIdsList,
            updateTrigger,
        }
    },
})
</script>
<style lang="sass" scoped>
@import '@/vars.sass'
::v-deep .v-input__append-outer
    margin-top: 9px
::v-deep .v-input__prepend-inner
    margin-top: 9px
.message-parts-slider-title
    color: $gray
    font-size: 12px
    line-height: 16px
    font-weight: $font-weight-medium
    letter-spacing: 1.07px
.slider-part
    display: flex
    align-items: center
    cursor: pointer
    width: 249px
    height: 40px
    border-left: 4px solid $gray-2
    &.active
        border-left: 4px solid $main
    &.disabled
        opacity: 0.5
        pointer-events: none
.slider-part-text
    margin-left: 8px
    color: $gray
    &.active
        color: $text-color
.message-parts-editor
    width: 684px
    height: 350px
    border: 1px solid $light-gray-4
    border-radius: 4px
    overflow-y: visible
    overflow-x: hidden

*
    scrollbar-width: auto
    scrollbar-color: $light-gray #ffffff

*::-webkit-scrollbar
    width: 14px
*::-webkit-scrollbar-track
    background: #ffffff

*::-webkit-scrollbar-thumb
    background-color: $light-gray
    border-radius: 8px
    border: 3px solid #ffffff
*::-webkit-scrollbar-thumb:hover
    background-color: $light-gray-2


.master
    display: flex
    align-items: center
    flex-direction: row
    width:100%
    &__input
        margin: 12px
        flex-grow: 0.5
</style>
