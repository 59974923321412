<template>
    <div class="settings-validation-item pb-2">
        <div v-if="title" class="settingsTitle mb-2">
            <img
                width="16px"
                class="icon mr-2"
                :src="
                    !warn && value
                        ? require('@/assets/icons/success-status.svg')
                        : require('@/assets/icons/danger-status.svg')
                "
            />
            {{ title }}
            <SmallHint class="ml-2" v-if="titleHintText" :text="titleHintText" :size="16"></SmallHint>
        </div>
        <div class="whatsapp-settings-form__setting">
            <BaseInputOutlined
                @paste="handlePaste"
                :disabled="disabled"
                hideDetails
                v-maska="maskOptions"
                :persistent-label="persistentLabel"
                class="validationInput"
                :label="label"
                :placeholder="placeholder"
                :value="currentPhone"
                @input="onInput"
            >
            </BaseInputOutlined>
            <div class="warn error--text" v-if="warn">
                {{ warn }}
            </div>
        </div>
    </div>
</template>

<script>
import { getCurrentInstance, ref, watch } from 'vue'
import SmallHint from '../SmallHint.vue'
import BaseInputOutlined from './BaseInputOutlined.vue'
import { reactive } from 'vue'
import { computed } from 'vue'
import { onMounted } from 'vue'
export default {
    props: {
        title: String,
        titleHintText: String,
        isBotConnected: Boolean,
        rule: Function,
        label: String,
        value: String,
        placeholder: String,
        persistentLabel: Boolean,
        disabled: Boolean
    },
    components: {
        SmallHint,
        BaseInputOutlined,
    },
    setup(props, { emit }) {
        const warn = ref('')
        const isWarn = ref(false)
        const currentPhone = ref('')
        let maskOptions = ref('+#*')
        const onInput = e => {
            currentPhone.value = e?.replace('+8', '+7')
            const cleanedValue = currentPhone.value?.replace(/[^\d+]/g, '');
            emit('input', cleanedValue)
            if (props.rule) {
                warn.value = props.rule(e)
                isWarn.value = !!warn.value
            }
        }
        const changeMask = function(phone) {
            const cleanPhone = phone?.startsWith('+') ? phone : `+${phone}`
            if(cleanPhone?.startsWith('+998') || cleanPhone?.startsWith('+971')) {
                maskOptions.value = '+###-##-#*'
            } else if(cleanPhone?.startsWith('+996')) {
                maskOptions.value = '+### ### #*'
            } else if(cleanPhone?.startsWith('+995')) {
                maskOptions.value = '+### (###) ###-#*'
            } else if(cleanPhone?.startsWith('+994')) {
                maskOptions.value = '+### ### ### ## #*'
            } else if(cleanPhone?.startsWith('+993')) {
                maskOptions.value = '+###-#-###-#*'
            } else if(cleanPhone?.startsWith('+992')) {
                maskOptions.value = '+### ## ### ## #*'
            } else if(cleanPhone?.startsWith('+380')) {
                maskOptions.value = '+### ## #*'
            } else if(cleanPhone?.startsWith('+375')) {
                maskOptions.value = '+### ## ###-##-#*'
            } else if(cleanPhone?.startsWith('+374')) {
                maskOptions.value = '+###-##-###-#*'
            } else if(phone?.startsWith('+373')) {
                maskOptions.value = '+###-####-#*'
            } else if (cleanPhone?.startsWith('+66')) {
                maskOptions.value = '+##-##-###-##-#*'
            } else if(cleanPhone?.startsWith('+62')) {
                maskOptions.value = '+## ## ### ### #*'
            } else if (cleanPhone?.startsWith('+44')) {
                maskOptions.value = '+## #### #*'
            } else if (cleanPhone?.startsWith('+7') || cleanPhone?.startsWith('+8')) {
                maskOptions.value = '+# (###) ###-##-#*'
            } else if (cleanPhone?.startsWith('+1')) {
                maskOptions.value = '+# (###) ###-#*'
            } else {
                maskOptions.value = '+#*'
            }
        }
        const handlePaste = (e) => {
            e.preventDefault()
            const pasted = e.clipboardData.getData('text')?.replace(/[^\d+]/g, '')
            const processedValue = pasted?.replace('+8', '+7')
            currentPhone.value = processedValue
            emit('input', processedValue)
            if (props.rule) {
                warn.value = props.rule(processedValue)
                isWarn.value = !!warn.value
            }
        }
        watch(isWarn, v => {
            emit('isWarn', v)
        })
        watch(currentPhone, newVal => {
            changeMask(newVal?.replace(/[^\d+]/g, ''))
        })
        onMounted(() => {
            currentPhone.value = props.value
            changeMask(currentPhone.value)
        })
        return {
            warn,
            onInput,
            maskOptions,
            currentPhone,
            handlePaste
        }
    },
}
</script>

<style scoped lang="sass">
@import '@/vars.sass'
.settingsTitle
    display: flex
    flex-direction: row
    align-items: center
    font-size: 14px

.settings-validation-item
    position: relative
    margin-bottom: 10px
    text-decoration: none

.settings-validation-item::marker
    display: none

    .validationInput
        position: relative

.warn
    position: absolute
    font-size: 12px
    bottom: -10px
</style>
