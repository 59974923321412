import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import { domain, clientId, audience } from './auth/auth_config.js'
import { createPinia, PiniaVuePlugin } from 'pinia'
import { Auth0Plugin } from './auth'
import VueScrollTo from 'vue-scrollto'
import * as Sentry from '@sentry/vue'
import sentryOpts from './plugins/sentry'
import cfg from '../config'
import moment from 'moment'
import VueTour from 'vue-tour'
import Maska from 'maska'
import messages from '@dataheroes/messages'
import KinescopePlayer from '@kinescope/vue-kinescope-player'
//
Vue.use(PiniaVuePlugin)
Vue.use(Maska)
const pinia = createPinia()
//Vue tour
require('vue-tour/dist/vue-tour.css')
Vue.use(VueTour)
//

Sentry.init(sentryOpts)
Sentry.setTag('buildType', cfg.buildType)
if (cfg.buildType === 'PRODUCTION') {
    //disable all logs
    console.log = function() {}
}
Vue.use(KinescopePlayer)
Vue.use(VueScrollTo)
Vue.use(Auth0Plugin, {
    domain,
    audience,
    clientId,
    onRedirectCallback: (appState: any) => {
        router.push(appState && appState.targetUrl ? appState.targetUrl : window.location.pathname)
    },
})
moment.locale('ru')
Vue.config.productionTip = false
Vue.prototype.moment = moment
Vue.prototype.$screen = window.screen

declare module 'vue/types/vue' {
    interface Vue {
        $auth: any
    }
}
new Vue({
    router,
    store,
    vuetify,
    pinia,
    render: h => h(App),
}).$mount('#app')
