<template>
    <div class="base-input-outlined-wrapper">
        <v-text-field
            v-bind="$attrs"
            v-on="$listeners"
            :style="`width: ${width}`"
            :type="type"
            :prepend-inner-icon="prependInnerIcon"
            :color="color"
            :placeholder="persistentLabel ? placeholder : label"
            :value="value"
            :hide-details="hideDetails"
            :rules="rules"
            outlined
            :label="persistentLabel ? label : undefined"
            :readonly="readonly"
            :disabled="disabled"
            dense
            class="no-arrows"
            :class="{
                'bold-placeholder': boldPlaceholder,
                'base-input-outlined': true,
                disabled: disabled,
            }"
            @input="update"
            :hint="hint"
            :persistent-hint="persistentHint"
        >
            <template v-slot:message="{ message }">
                <v-row class="ml-1" justify="space-between">
                    <span>{{ message }}</span>

                    <v-tooltip bottom color="#000000DE" max-width="190px" v-if="tooltipText">
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon small class="hint-icon" v-bind="attrs" v-on="on">mdi-help-circle </v-icon>
                        </template>
                        <span class="white--text"> {{ tooltipText }}</span>
                    </v-tooltip>
                </v-row>
            </template>
        </v-text-field>
    </div>
</template>

<script>
// @ is an alias to /src

export default {
    name: 'TextField',
    data: () => ({}),
    //hint - короткая подсказка
    //tooltipText - расширенный техт подсказки
    props: {
        value: {
            type: null,
            default: undefined,
        },
        type: {
            type: String,
            default: undefined,
        },
        width: {
            type: Number,
            default: undefined,
        },
        prependInnerIcon: {
            type: String,
            default: undefined,
        },
        color: {
            type: String,
            default: '#465468',
        },
        label: {
            type: String,
            default: undefined,
        },
        placeholder: {
            type: String,
            default: undefined,
        },
        persistentLabel: {
            type: Boolean,
            default: false,
        },
        rules: {
            type: null, //any
            default: undefined,
        },
        hint: {
            type: String,
            default: undefined,
        },
        persistentHint: {
            type: Boolean,
            default: undefined,
        },
        tooltipText: {
            type: String,
            default: undefined,
        },
        hideDetails: {
            type: Boolean,
            default: false,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        boldPlaceholder: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        inputType: {
            type: String
        }
    },
    components: {},
    methods: {
        update(newValue) {
            this.$emit('input', newValue, 'hello')
        },
        onPaste(e) {
            this.$emit('paste', e)
        }
    },
    created() {},
    mounted() {},
}
</script>
<style lang="sass" scoped>
@import '@/vars.sass'
.disabled
    opacity: 0.5

.hint-icon
    color: $hint-icon-color
    opacity: 0.38
.hint-icon:hover
    color: black
    cursor: pointer
    opacity: 1
::v-deep .v-input__slot
    padding-left: 8px !important
    padding-right: 8px !important
::v-deep .v-input__prepend-inner
    padding: 0 8px 8px 0 !important
::v-deep .bold-placeholder input::placeholder
    color: $text-color !important
    letter-spacing: 1.25px
    font-weight: 450
    font-size: 14px
::v-deep input
    overflow: hidden
    text-overflow: ellipsis
    white-space: nowrap
::v-deep fieldset
    border-color: $light-gray-2

::v-deep .v-text-field--outlined.v-input--is-focused fieldset, .v-text-field--outlined.v-input--has-state fieldset
    border: 1px solid currentColor
::v-deep .no-arrows input[type="number"]::-webkit-inner-spin-button, ::v-deep .no-arrows input[type="number"]::-webkit-outer-spin-button 
  -webkit-appearance: none
  margin: 0

</style>
