
import {
    computed,
    defineComponent,
    ref,
    shallowRef,
    watch,
    PropType,
    onMounted,
    getCurrentInstance,
} from 'vue'
import * as _ from 'lodash'
import api from '../../api/api'
import { useProject } from '../../hooks/useProject'
import SpinnerLoader from '../SpinnerLoader.vue'
import { useDropZone } from '../../hooks/useDropzone'
interface UploadedFile {
    src: string
    originalName: string
    mimeType: string
    size?: string
    thumbnail?: {
        src: string
        originalName: string
        mimeType: string
        size?: string
    }
}
export default defineComponent({
    components: { SpinnerLoader },
    props: {
        value: { type: Object as PropType<UploadedFile>, required: true },
        maxFileSize: { type: Number },
        mimeTypes: { type: Array as PropType<string[]> },
        excel: { type: Boolean },
    },
    setup(props, { emit }) {
        const root = getCurrentInstance()!.proxy
        const { project } = useProject()
        const fileUploader = ref<any>(null)
        const allowedMimeTypes = props.mimeTypes ?? [
            'image/png',
            'image/jpeg',
            'application/pdf',
        ]
        const update = (v: any | null) => {
            emit('input', v)
        }
        const loading = ref(false)
        const excelFileName = ref('')

        const showImagePreview = computed(() => {
            const imageExtensions = ['.png', '.jpg', '.gif', '.jpeg']
            return !props.excel && props.value && imageExtensions.some(ext => props.value?.src?.endsWith(ext))
        })
        const showVideoPreview = computed(() => {
            const videoExtensions = ['.mp4']
            return !props.excel && props.value && videoExtensions.some(ext => props.value?.src?.endsWith(ext))
        })
        const showDocPreview = computed(() => {
            const docExtensions = ['.pdf']
            return props.value && docExtensions.some(ext => props.value?.src?.endsWith(ext))
        })

        const openUploadWindow = () => {
            const fileInput = root.$refs['fileInput'] as HTMLInputElement
            fileInput.click()
        }
        const clearFile = () => {
            const fileInput = root.$refs['fileInput'] as HTMLInputElement
            fileInput.value = ''
            excelFileName.value = ''
            update({})
        }
        const onInputChange = (e: Event) => {
            const target = e.target as HTMLInputElement
            const files = target.files!
            uploadFile(files)
        }
        const uploadFile = async (files: File[] | FileList | null) => {
            if (!files) return
            loading.value = true
            const file = _.first<File>(files)
            if (!allowedMimeTypes.includes(file?.type!)) {
                root.$store.dispatch('callNotify', 'Не верный формат файла')
                loading.value = false
                return
            }

            if (props.maxFileSize && file && file?.size > props.maxFileSize) {
                root.$store.dispatch('callNotify', 'Превышен максимальный размер файла')
                clearFile()
                loading.value = false
                return
            }
            if (!props.excel) {
                const { data, error } = await api.file.uploadMedia({
                    projectId: project.value.id,
                    file: file!,
                })
                if (error) {
                    root.$store.dispatch('callNotify', 'Произошла ошибка при загрузке файла')
                    loading.value = false
                    return
                }
                update(data!.url)
            } else {
                excelFileName.value = file.name
                update({ projectId: project.value.id, file: file! })
            }
            if (file?.type.startsWith('video/')) {
                await generateVideoThumbnail(file!)
            }
            loading.value = false
        }
        const generateVideoThumbnail = (file: File) => {
            return new Promise(resolve => {
                const video = document.createElement('video')
                const canvas = document.createElement('canvas')
                const url = URL.createObjectURL(file)

                video.src = url
                video.addEventListener('loadeddata', async () => {
                    video.currentTime = 1 // Берем кадр на 1-й секунде
                })

                video.addEventListener('seeked', () => {
                    canvas.width = video.videoWidth
                    canvas.height = video.videoHeight
                    const ctx = canvas.getContext('2d')!
                    ctx.drawImage(video, 0, 0, canvas.width, canvas.height)

                    canvas.toBlob(async blob => {
                        const thumbFile = new File([blob!], 'thumbnail.png', { type: 'image/png' })
                        const { data } = await api.file.uploadMedia({
                            projectId: project.value.id,
                            file: thumbFile,
                        })

                        update({
                            ...props.value,
                            thumbnail: data!.url,
                        })

                        URL.revokeObjectURL(url)
                        resolve(true)
                    }, 'image/png')
                })
            })
        }

        const { isOverDropZone } = useDropZone(fileUploader, {
            onDrop: uploadFile,
        })
        return {
            update,
            uploadFile,
            openUploadWindow,
            clearFile,
            showImagePreview,
            showDocPreview,
            loading,
            isOverDropZone,
            fileUploader,
            onInputChange,
            allowedMimeTypes,
            excelFileName,
            showVideoPreview,
        }
    },
})
